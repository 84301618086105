<template>
  <div class="m-newsD-box">
      <bannerStatic :num="3" />    
      <div class="newDetails-box m-minheight">
          <div class="article-box" v-html="content.newsContent">
          </div>
      </div>
      <router-link class="more" to="/MNews">新闻列表</router-link>
  </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/mBannerStatic'
import { getNewDetails } from '@/api/data'
import  { newsImg } from '@/config/appSetting'
export default {
  name: 'NewDetails',
  components:{
    bannerStatic
  },
  data() {
      return {
          imgUrl:'',
          content:{},
          newsImg
      }
  },
  mounted() {
     getNewDetails({},this.$route.params.id).then(res => {
        if (res.state) {
          this.content = res.data ? res.data : {}
          this.imgUrl= newsImg+this.content.newsImageUrl
        }
     })
  },
  methods: {
  },
}
</script>

<style  lang="less" scoped >
.m-minheight{
    min-height: 70vh;
}
.m-newsD-box{
  background: #fff;
  border-bottom: 1px solid #ccc;
  width: 100%;
  .newDetails-box{
      width: 100%;
      padding:.266667rem /* 20/75 */ .4rem /* 30/75 */;
      box-sizing: border-box;
      overflow: hidden;
      text-align: left;
        .article-box{
          overflow: hidden;
          img{
            max-width: 100% !important;
          }
        }
  }
  .more{
      display: inline-block;
      width: 3.2rem /* 240/75 */;
      padding:.266667rem /* 20/75 */ 0;
      border: solid 1px #999;
      line-height: .373333rem /* 28/75 */;
      text-align: center;
      font-size: .373333rem /* 28/75 */;
      color: #666;
      margin:30px auto;
  }
}

</style>
<style>
.m-newsD-box .article-box *{
   max-width: 100% !important;
}
</style>

